'use client'

import { useCanSendAnalytics } from 'analytics/hooks/useCanSendAnalytics/useCanSendAnalytics'
import { CONSENT_COOKIE } from 'cookies/constants'
import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect } from 'react'
import { isBot } from 'utils/isBot/IsBot'

import { getCookie } from '../../utils/getCookies'
import { SendAnalyticsConfirmation } from '../../utils/sendAnalyticsConfirmation'
import { CookiesContent } from './CookiesContent'

export const CookiesBanner = () => {
	const { pageType, isApp } = useMasterData()
	const {
		show,
		setShow,
		setCookiesConsent,
		blockedPageTypes,
		cookiesConsent,
		lastAction,
		categories,
		step,
	} = useCookiesContext()
	const { canSendAnalytics } = useCanSendAnalytics()

	useEffect(() => {
		if (!!getCookie(CONSENT_COOKIE) || isApp) {
			setCookiesConsent(true)
			return
		}

		if (!blockedPageTypes.includes(pageType) && !isBot() && !isApp) {
			setShow(true)
		}
	}, [])

	useEffect(() => {
		if (canSendAnalytics && lastAction && cookiesConsent) {
			SendAnalyticsConfirmation({
				section: lastAction,
				categories,
				step,
			})
		}
	}, [canSendAnalytics, cookiesConsent, lastAction, step, categories])

	return show ? <CookiesContent /> : null
}
