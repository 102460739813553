'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useMasterDataIsReady } from 'master-data/hooks/useMasterDataIsReady/useMasterDataIsReady'
import { usePathname, useSearchParams } from 'next/navigation'
import {
	type ReactNode,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useSession } from 'session/src/hooks/useSession'

import { sendQueuedEvents } from '../events/generics/queue'
import { sendConsentMode } from '../events/gtag/sendConsentMode/sendConsentMode'
import { sendPageview } from '../events/gtag/sendPageview/sendPageview'
import { setGtag } from '../events/gtag/setGtag/setGtag'
import { isPageViewSent } from '../utils/isPageViewSent/isPageViewSent'
import { startGTM } from '../utils/startGTM/startGTM'
import { AnalyticsImpressionsProvider } from './AnalyticsImpressionsProvider'

export type AnalyticsContextType = {
	canSendAnalytics: boolean
}

export const AnalyticsContext = createContext<AnalyticsContextType>({
	canSendAnalytics: false,
})

interface AnalyticsProviderProps {
	children: ReactNode
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
	const masterData = useMasterData()

	const [isGTMLoaded, setIsGTMLoaded] = useState(false)
	const pathname = usePathname()
	const searchParams = useSearchParams()

	const { masterDataIsReady } = useMasterDataIsReady()

	const session = useSession()
	const { cookiesConsent, categories } = useCookiesContext()

	useEffect(() => {
		window.dataLayer ||= []
		window.dataLayer.length = 0
		setGtag()
	}, [])

	useEffect(() => {
		if (window.dataLayer.length && isPageViewSent()) {
			window.dataLayer.length = 0
			startGTM()
			sendPageview(setIsGTMLoaded, masterData, session)
		} else {
			startGTM()
		}
	}, [pathname, searchParams])

	useEffect(() => {
		if (session.isReady && cookiesConsent) {
			sendConsentMode({ categories, session })
		}
	}, [categories, session.isReady, cookiesConsent])

	useEffect(() => {
		if (session.isReady && cookiesConsent && masterDataIsReady) {
			const pageViewSent = isPageViewSent()
			if (!pageViewSent) {
				sendPageview(setIsGTMLoaded, masterData, session)
			}
			sendQueuedEvents()
		}
	}, [masterDataIsReady, session.isReady, cookiesConsent])

	const value = useMemo(
		() => ({
			canSendAnalytics: Boolean(
				session.isReady && cookiesConsent && isGTMLoaded && masterDataIsReady
			),
		}),
		[session.isReady && cookiesConsent && isGTMLoaded && masterDataIsReady]
	)

	return (
		<AnalyticsImpressionsProvider>
			<AnalyticsContext.Provider value={value}>
				{children}
			</AnalyticsContext.Provider>
		</AnalyticsImpressionsProvider>
	)
}
